// ------------------------------------------------------------------
// --------------------------- ui library ---------------------------
// ------------------------------------------------------------------

@import '~antd/dist/antd';

// -------------------------------------------------------------------
// --------------------------- custom fonts ---------------------------
// -------------------------------------------------------------------

@font-face {
  font-family: Gotham;
  src: url(./../assets/fonts/Bold.ttf);
  font-weight: bolder;
  font-display: swap;
}

@font-face {
  font-family: Gotham;
  src: url(./../assets/fonts/Medium.ttf);
  font-weight: bold;
  font-display: swap;
}

@font-face {
  font-family: Gotham;
  src: url(./../assets/fonts/Regular.ttf);
  font-weight: normal;
  font-display: swap;
}

// ------------------------------------------------------------------
// ----------------------------- mixins -----------------------------
// ------------------------------------------------------------------

@mixin transform($property) {
  -webkit-transform: $property;
  -moz-transform: $property;
  -o-transform: $property;
  -ms-transform: $property;
  transform: $property;
}

// ------------------------------------------------------------------
// --------------------------- variables ----------------------------
// ------------------------------------------------------------------

// refactor css when i get the chance

// sizes
$max-supported-app-width: 2200px;

// ------------------------------------------------------------------
// ----------------------------- global -----------------------------
// ------------------------------------------------------------------

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: Gotham, 'Avenir', 'Helvetica', sans-serif;
  font-weight: normal;
  font-size: 18px;
}

.ultra {
  width: 100%;
  min-height: 100vh;
  max-width: $max-supported-app-width;
  display: block;
  margin: auto;
  position: relative;
  padding-bottom: 100px;
}

// ------------------------------------------------------------------
// ----------------------------- navbar -----------------------------
// ------------------------------------------------------------------

.navbar {
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 72px;
  z-index: 100;
  display: flex;
  background-color: white;
  border-bottom: 1px solid rgba(217, 219, 224, 0.5);
  box-shadow: 0px 4px 10px 0px rgba(53, 53, 53, 0.07);
}

.navbar-about {
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    cursor: pointer;

    h3 {
      color: rgb(0, 204, 153);
    }
  }

  h3 {
    transition: 0.2s;
    margin: 0;
    text-align: center;
    font-weight: bold;
  }
}

.navbar-items {
  width: 100%;
  max-width: 1075px;
  margin: 0 auto;
  padding: 0px 25px;
  display: flex;
  align-items: center;
}

.navbar-space {
  flex-grow: 1;
}

.navbar-links {
  display: flex;
  justify-content: space-between;
  align-content: center;
  width: 100px;

  div {
    font-size: 12px;
    color: rgba(45, 49, 56, 0.8);
    text-transform: uppercase;
    transition: 0.2s;
    letter-spacing: 1.3px;

    &:hover {
      cursor: pointer;
      color: rgba(0, 204, 153, 0.8);
      border-bottom: 1px solid rgba(0, 204, 153, 0.8);
    }
  }
}

.banner {
  width: 100%;
  height: 260px;
  overflow: hidden;
  background-color: rgb(65, 65, 65);

  .banner-image {
    width: 100%;
    height: 100%;
    opacity: 0.7;
    object-fit: cover;
  }
}

// ------------------------------------------------------------------
// ----------------------------- footer -----------------------------
// ------------------------------------------------------------------

.footer {
  position: absolute;
  bottom: 0;
  background-color: black;
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;

  .copyright {
    color: #8f95a3;
    text-align: center;
    font-size: 12px;
    transition: 0.2s;

    &:hover {
      color: white;
    }
  }
}

// ------------------------------------------------------------------
// ------------------------------ modal -----------------------------
// ------------------------------------------------------------------

.ant-modal-root {
  .ant-modal-mask {
    background-color: rgba(255, 255, 255, 0.7);
  }

  .ant-modal-content {
    box-shadow: none;
    height: 100%;
    width: 100%;
  }

  .ant-modal,
  .ant-modal-body {
    height: 100%;
    width: 100% !important;
    padding: 0;
    margin: 0;
    max-width: 100%;
    position: relative;
  }
}

.modal-header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 15px 30px 0 30px;

  .modal-close-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;

    span {
      font-size: 24px;
      transition: 0.2s;
    }

    &:hover {
      cursor: pointer;

      span {
        color: rgba(0, 0, 0, 0.342);
      }
    }
  }
}

.modal-item-name {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0px 36px;
  margin: 0 0 8px;

  h2 {
    font-weight: bold;
    margin: 0;
    padding: 0;
    text-align: left;
    font-size: 24px;
  }

  img {
    width: 24px;
    height: 24px;
    margin-bottom: 5px;
    margin-left: 14px;
  }
}

.modal-item-description {
  font-size: 15px;
  color: rgb(143, 149, 163);
  margin: 13px 0;
  padding: 0 36px;
}

.modal-special-instructions-container {
  margin-top: 30px;

  .modal-special-instructions-header {
    padding: 0 36px;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 12px;
  }

  .modal-textarea-wrapper {
    margin-top: 6px;
    border-bottom: 1px solid rgba(217, 219, 224, 0.5);
    border-top: 1px solid rgba(217, 219, 224, 0.5);

    textarea {
      resize: none;
      padding: 10px 36px;
      min-height: 120px;
    }
  }
}

.modal-footer {
  border-top: 1px solid rgba(217, 219, 224, 0.5);
  position: absolute;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 16px 20px;
}

.modal-item-count-wrapper {
  border: 1px solid rgba(217, 219, 224, 0.5);
  border-radius: 100px;
  margin: 0px 12px 0px auto;
  color: rgb(45, 49, 56);
  height: 56px;
  width: 168px;
  max-width: 168px;
  display: flex;
  align-items: center;

  div {
    flex: 1;
    text-align: center;
  }

  .modal-change-count {
    font-size: 16px;
    flex: 2;
    transition: 0.2s;
    user-select: none;

    &:hover {
      cursor: pointer;
      color: rgba(0, 0, 0, 0.342);
    }

    &.disabled {
      color: rgba(0, 0, 0, 0.342);
    }
  }
}

.modal-add-to-cart {
  border-radius: 28px;
  margin: 0;
  padding: 0 25px;
  background-color: rgb(0, 204, 153);
  color: white;
  height: 56px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  transition: 0.2s;
  font-size: 13px;
  user-select: none;

  &:hover {
    cursor: pointer;
    background-color: rgba(0, 204, 153, 0.8);
  }

  .modal-add-to-cart-text {
    text-transform: uppercase;
    flex: 1 1 0%;
    text-align: center;
  }

  .modal-add-to-cart-price {
    flex: 1 1 0%;
    text-align: end;
  }

  .modal-add-to-cart-space {
    flex: 1 1;
  }
}

.ant-input-textarea {
  position: relative;

  &::after {
    float: none;
    position: absolute;
    right: 36px;
    bottom: -25px;
    font-size: 12px;
  }
}

// ------------------------------------------------------------------
// ------------------------------ menu ------------------------------
// ------------------------------------------------------------------

.menu-container {
  width: 100%;

  .menu-sticky-bar {
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 70px;
    z-index: 100;
    display: flex;
    background-color: white;
    border-bottom: 1px solid rgba(217, 219, 224, 0.5);
    box-shadow: 0px 4px 10px 0px rgba(53, 53, 53, 0.07);
  }

  .menu-sticky-bar-items {
    width: 100%;
    max-width: 1075px;
    margin: 0 auto;
    padding: 0px 25px;
    display: flex;
    align-items: center;

    .menu-sticky-bar-search-and-select {
      flex: 1;
      display: flex;
      align-items: center;
    }
  }

  .menu-collapsible-container {
    display: flex;
    align-items: center;
    transition: 0.2s;
    transform-origin: left;
    position: relative;

    .dropdown-menu {
      position: absolute;
      width: 350px;
      height: 380px;
      background-color: white;
      top: 51px;
      left: -8px;
      overflow-y: auto;
      z-index: 100;
      box-shadow: rgba(34, 34, 34, 0.08) 0px 2px 8px 0px;
      display: flex;
      flex-direction: column;
      align-items: center;

      .dropdown-option {
        width: 100%;
        min-height: 47px;
        height: 47px;
        position: relative;

        .active-indicator {
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 3px;
          background-color: black;
          transition: 0.2s;
        }

        &:hover {
          cursor: pointer;

          .dropdown-option-text {
            color: rgba(0, 204, 153, 0.8);
          }

          .active-indicator {
            background-color: rgba(0, 204, 153, 0.8);
          }
        }

        .dropdown-option-text {
          font-size: 14px;
          margin: 0 20px;
          height: 100%;
          border-top: 1px solid rgb(239, 239, 239);
          display: flex;
          align-items: center;
          color: rgba(45, 49, 56, 0.8);
          transition: 0.2s;
        }

        #dropdown-text-id-0 {
          border-top: none;
        }
      }
    }
  }

  .menu-category-select {
    width: 210px;
  }

  .menu-search-container {
    transition: 0.2s;
  }

  .cart-button-container {
    display: flex;
    align-items: center;
    height: 100%;
    width: 135px;
    margin-left: 20px;

    .cart-button {
      border: 1px solid rgba(217, 219, 224, 0.5);
      border-radius: 50px;
      width: 100%;
      height: 45px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;

      .cart-button-badge {
        position: absolute;
        top: 5px;
        right: 0;
        background-color: rgb(0, 204, 153);
        border-radius: 100px;
        text-align: center;
        color: white;
        height: 18px;
        width: 18px;
        font-size: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      &:hover {
        cursor: pointer;
        span {
          color: rgb(0, 204, 153);
        }
      }

      .vertical-divider {
        height: 18px;
        margin: 0 8px;
      }

      span {
        text-transform: uppercase;
        font-size: 14px;
        transition: 0.2s;
      }
    }
  }

  .vertical-divider {
    height: 30px;
    width: 1px;
    margin: 0 17px;
    background: rgba(217, 219, 224, 0.5);
  }

  .menu-items {
    width: 100%;
    max-width: 1075px;
    margin: 0 auto;
    padding: 0px 35px;

    .menu-section {
      width: 100%;
      height: auto;
      margin-top: 30px;

      .category-name {
        width: 100%;
        border-bottom: 1px solid rgba(217, 219, 224, 0.5);
        font-weight: bold;
        font-size: 21px;
      }

      .category-extra-info {
        font-size: 13px;
        color: rgba(143, 149, 163, 0.9);
        font-weight: normal;
        margin-bottom: 3px;
      }
    }
  }

  .cards-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .menu-card {
      height: 128px;
      border: 1px solid rgba(217, 219, 224, 0.5);
      background-color: white;
      width: 487px;
      margin: 16px 0px;
      padding: 20px 20px 15px 20px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      &:hover {
        cursor: pointer;
        background-color: rgb(247, 247, 248);
      }

      .card-description {
        font-size: 13px;
        color: rgba(143, 149, 163, 0.9);
      }

      .card-price {
        font-size: 13px;
        color: rgb(0, 204, 153);
      }
    }
  }
}

.card-name-spicy-container {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  h5 {
    font-weight: bold;
    margin: 0;
    padding: 0;
    text-align: left;
    font-size: 15px;
  }

  img {
    width: 15px;
    height: 15px;
    margin-bottom: 3px;
    margin-left: 10px;
  }
}

.highlighted-text {
  color: rgb(0, 204, 153);
}

// -------------------------------------------------------------------
// ----------------------------- checkout ----------------------------
// -------------------------------------------------------------------

.checkout-container {
  width: 100%;
  max-width: 1075px;
  margin: 0 auto;
  padding: 0 35px;
}

.checkout-steps {
  margin: 35px 0;
}

.ant-steps-item {
  &:hover {
    cursor: pointer;

    .ant-steps-item-icon {
      background-color: rgb(0, 204, 153);
      border-color: rgb(0, 204, 153);

      .ant-steps-icon {
        color: white;
      }
    }

    .ant-steps-item-container
      > .ant-steps-item-content
      > .ant-steps-item-title {
      color: rgb(0, 204, 153);
    }
  }
}

.ant-steps-item-finish {
  .ant-steps-item-icon {
    border-color: rgb(0, 204, 153);

    .ant-steps-icon {
      color: rgb(0, 204, 153);
    }
  }

  .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
    &::after {
      background-color: rgb(0, 204, 153);
    }
  }
}

.ant-steps-item-active {
  .ant-steps-item-icon {
    border-color: rgb(0, 204, 153);
  }
}

.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
  background: rgb(0, 204, 153);
}

.checkout-item-card {
  width: 100%;
  min-height: 120px;
  padding: 20px 0;
  border-top: 1px solid rgba(217, 219, 224, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.card-top-row {
  display: flex;
  justify-content: space-between;
  align-content: center;
}

.checkout-item-display,
.checkout-confirm-display {
  padding: 20px;

  h5 {
    font-size: 16px;
    font-weight: bold;
  }

  .checkout-item-instructions,
  .checkout-item-card-bottom {
    font-size: 14px;

    span {
      font-weight: bold;
    }
  }
}

.checkout-item-card-bottom {
  display: flex;
  justify-content: space-between;
}

.checkout-delete-item {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 30px;

  &:hover {
    cursor: pointer;
  }
}

.checkout-item-instructions {
  margin: 5px 0 30px 0;
}

.checkout-item-display {
  flex: 5;
  min-height: 350px;

  & > :last-child {
    margin-bottom: 50px;
  }
}

.checkout-confirm-display {
  flex: 3;
  margin-left: 10px;

  .checkout-space-between:last-child {
    border-top: 1px solid rgba(217, 219, 224, 0.5);
    border-bottom: 1px solid rgba(217, 219, 224, 0.5);
    padding: 15px 0px;
    margin: 40px 0px 20px 0px;
  }
}

.checkout-items-and-confirmation-container {
  display: flex;
}

.checkout-confirm-display {
  height: 350px;
  display: flex;
  flex-direction: column;
}

.checkout-price-info-container {
  border-top: 1px solid rgba(217, 219, 224, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  font-size: 16px;
}

.checkout-space-between {
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
}

.checkout-price-subtotal-info {
  margin-top: 10px;
}

.checkout-confirm-button {
  border-radius: 28px;
  margin: 0;
  padding: 0 25px;
  background-color: rgb(0, 204, 153);
  color: white;
  height: 56px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.2s;
  font-size: 16px;
  user-select: none;

  &:hover {
    cursor: pointer;
    background-color: rgba(0, 204, 153, 0.8);
  }
}

.checkout-item-display-title,
.checkout-confirm-display-title {
  font-weight: bold;
  font-size: 21px;
}

.checkout-empty-icon {
  height: 350px;
  border-top: 1px solid rgba(217, 219, 224, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.checkout-contact-info {
  width: 100%;
  border-top: 1px solid rgba(217, 219, 224, 0.5);
}

.ant-form-item {
  margin: 20px 0 20px 0;
  height: 35px;
}

@media only screen and (max-width: 575px) {
  .ant-form .ant-form-item .ant-form-item-label,
  .ant-form .ant-form-item .ant-form-item-control {
    flex: 0 1 auto;
  }

  .ant-form-item-no-colon {
    b {
      margin-right: 10px;
    }
  }

  .ant-col.ant-form-item-control {
    flex: 1 !important;
  }
}

.ant-btn {
  &:hover,
  &:active,
  &:focus {
    border-color: rgba(0, 204, 153, 0.8);
    color: rgba(0, 204, 153, 0.8);
  }
}

.ant-btn.ant-btn-primary {
  border-color: #00cc99;
  background: #00cc99;

  &:hover,
  &:active,
  &:focus {
    border-color: transparent;
    background: rgba(0, 204, 153, 0.8);
    color: white;
  }
}

// -------------------------------------------------------------------
// ---------------------------- info page ----------------------------
// -------------------------------------------------------------------

.landing-page-wrapper {
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  max-width: 1075px;
  padding: 0 35px;
}

.landing-banner-wrapper {
  height: 650px;
  display: grid;

  grid-template-columns: 3fr 2fr;
  grid-template-areas:
    '. cartoonPicture'
    'hookText cartoonPicture'
    'orderButton cartoonPicture'
    '. cartoonPicture';

  h1 {
    grid-area: hookText;
    text-align: left;
    font-weight: bold;
    font-size: 300%;
    overflow-wrap: break-word;
    align-self: end;
  }

  .landing-order-button {
    grid-area: orderButton;
    border-radius: 28px;
    margin: 0;
    padding: 0 25px;
    background-color: rgb(0, 204, 153);
    color: white;
    height: 56px;
    max-width: 200px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.2s;
    font-size: 16px;
    user-select: none;

    &:hover {
      cursor: pointer;
      background-color: rgba(0, 204, 153, 0.8);
    }
  }

  .landing-banner-picture {
    grid-area: cartoonPicture;
    height: 300px;
    align-self: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.takeout-cartoon-image {
  width: 100%;
  height: auto;
  animation: cartoonAnimation 3s ease infinite;
}

@keyframes cartoonAnimation {
  0% {
    transform: translateY(-5px);
  }

  50% {
    transform: translateY(5px);
  }

  100% {
    transform: translateY(-5px);
  }
}

.info-section-wrapper {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.info-section-block {
  margin: 20px 0;
  min-height: 200px;
  width: 380px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  h3 {
    font-size: 1.1em;
    font-weight: bold;
  }

  div {
    font-size: 0.98em;
  }
}

.info-section-block-section {
  flex: 1;
}

.hours-row {
  display: flex;
  margin-bottom: 5px;
  align-items: center;

  div {
    &:first-child {
      flex: 1;
      text-align: left;
      justify-content: flex-start;
    }

    &:last-child {
      flex: 2;
      text-align: right;
      justify-content: flex-end;
    }

    display: flex;
    align-items: center;
  }
}

.info-section-block .row-active {
  color: #00c493;
  font-weight: bold;
  font-size: 1.02em;
  transform: translateY(2px);
}

// -------------------------------------------------------------------
// --------------------------- media rules ---------------------------
// -------------------------------------------------------------------

// when screen width reaches 1060px and below
@media only screen and (max-width: 1060px) {
  // adjust the banner image height
  .banner {
    height: 130px;
  }

  // resize cards
  .menu-container {
    .menu-items {
      .menu-section {
        .category-name {
          border-bottom: none;
          margin-bottom: 0;
          font-size: 20px;
        }
      }
    }

    .cards-container {
      display: block;

      .menu-card {
        height: 150px;
        border: none;
        border-top: 1px solid rgba(217, 219, 224, 0.5);
        width: 100%;
        margin: 0;
        padding-left: 0;

        &:hover {
          background-color: white;
        }

        .card-name-spicy-container {
          h5 {
            font-size: 15px;
          }

          img {
            width: 15px;
            height: 15px;
            margin-bottom: 3px;
            margin-left: 10px;
          }
        }

        .card-description {
          font-size: 13px;
          margin-top: 5px;
        }

        .card-price {
          font-size: 13px;
        }
      }
    }
  }

  // change modal display
  .modal-footer {
    border: none;
    flex-wrap: wrap;
  }

  .modal-item-count-wrapper {
    margin: 0 0 20px 0;
  }
}

// when screen width reaches 768px and below
@media only screen and (max-width: 768px) {
  .navbar-items {
    padding: 0px 20px;
  }

  .menu-container {
    .menu-sticky-bar-items {
      padding: 0px 9px;
    }

    .menu-items {
      padding: 0px 20px;
    }

    // resize category picker
    .menu-collapsible-container {
      width: 100%;

      .dropdown-menu {
        position: absolute;
        width: 100vw;
        height: 100vh;
        padding-bottom: 250px;
        left: -9px;

        .dropdown-option {
          width: 100%;
          min-height: 60px;
          height: 60px;

          .active-indicator {
            display: none;
          }

          .dropdown-option-text {
            margin: 0 20px;
            height: 100%;
          }
        }
      }

      .menu-category-select {
        width: 100%;
      }
    }

    // resize cart button
    .cart-button-container {
      width: 45px;
      margin: 0;

      .cart-button {
        border: none;

        &:hover {
          span {
            color: black;
          }
        }

        span {
          font-size: 20px;
        }
      }
    }
  }

  .checkout-item-display,
  .checkout-confirm-display {
    border: none;
    padding: 0;
  }

  .checkout-items-and-confirmation-container {
    display: block;
  }

  .checkout-confirm-display {
    margin-left: 0;
  }

  .checkout-confirm-display {
    height: 300px;
  }

  .landing-page-wrapper {
    padding: 0 20px;
  }

  .landing-banner-wrapper {
    grid-template-columns: 1fr;
    grid-template-rows: 150px auto auto;
    grid-template-areas:
      'hookText'
      'cartoonPicture'
      'orderButton';

    h1 {
      text-align: center;
      font-size: 200%;
      margin: 0;
    }

    .landing-order-button {
      max-width: 600px;
      margin-top: 20px;
      justify-self: center;
    }

    .landing-banner-picture {
      height: 250px;

      img {
        height: 250px;
        width: auto;
      }
    }
  }
}

@media only screen and (max-width: 900px) {
  .info-section-block {
    width: 100%;
  }
}

// when screen width reaches 360px and below
@media only screen and (max-width: 360px) {
  // resize cards
  .menu-container {
    .menu-items {
      .menu-section {
        .category-name {
          font-size: 18px;
        }
      }
    }

    .cards-container {
      .menu-card {
        height: 150px;

        .card-name-spicy-container {
          h5 {
            font-size: 14px;
          }

          img {
            width: 14px;
            height: 14px;
            margin-bottom: 3px;
            margin-left: 10px;
          }
        }

        .card-description {
          font-size: 12px;
        }

        .card-price {
          font-size: 12px;
        }
      }
    }
  }
}

// for screens 1060px wide and above
@media screen and (min-width: 1060px) {
  .ant-modal-root {
    .ant-modal {
      max-width: 524px;
      max-height: 600px;
      box-shadow: rgba(34, 34, 34, 0.15) 0px 2px 20px 0px;
    }
  }

  .modal-add-to-cart-space {
    display: none;
  }
}

// when screen width reaches >= max supported width
@media only screen and (min-width: $max-supported-app-width) {
  .ultra {
    border-left: solid 1px rgb(235, 235, 235);
    border-right: solid 1px rgb(235, 235, 235);
  }
}
